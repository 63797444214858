/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {getAllInmuebles} from "@/api/inmueble"
import {getAllConds} from "@/api/condominio"
import {getAssociates} from "@/api/asociados"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {CSVLink} from "react-csv"
import dayjs from "dayjs"
import Page from "@/layouts/Page"
import {PROD_URL} from "@/constants"

interface Condos {
   [key: string]: string
}

interface Asociates {
   [key: string]: string
}

function ExcelViewInm() {
   const [inms, setInms] = useState<any[]>([])
   const [condos, setCondos] = useState<Condos>({})
   const [asociates, setAsociates] = useState<Asociates>({})
   const [loading, setLoading] = useState<boolean>(false)
   const navigate = useNavigate()

   const headers = [
      {label: "Ver Inmueble", key: "verInmueble"},
      {label: "Nombre", key: "name"},
      {label: "Dirección", key: "address"},
      {label: "Descripción", key: "desc"},
      {label: "Desc Breve", key: "desc2"},
      {label: "Notas públicas", key: "notes"},
      {label: "Notas privadas", key: "privateNotes"},
      {label: "URL CAD", key: "urlCAD"},
      {label: "Condominio", key: "condo"},
      {label: "Tipo de propiedad", key: "type"},
      {label: "Capacidad", key: "capacity"},
      {label: "Superficie mts2", key: "mts"},
      {label: "Construcción mts2", key: "mtsConstruction"},
      {label: "Pisos", key: "floor"},
      {label: "Niveles", key: "levels"},
      {label: "Habitaciones", key: "rooms"},
      {label: "Baños", key: "bathrooms"},
      {label: "Cajones de estacionamiento", key: "parkingLots"},
      {label: "Precio Mensual Anual", key: "costos.mensual_anual"},
      {label: "Precio Mensual Temporada", key: "costos.mensual_regular"},
      {label: "Precio Noche Alta", key: "costos.noche_alta"},
      {label: "Precio Noche Regular", key: "costos.noche_regular"},
      {label: "Precio Noche Media", key: "costos.noche_media"},
      {label: "Precio por metro", key: "pricePerMeter"},
      {label: "Mantenimiento", key: "maintenance"},
      {label: "Precio Venta", key: "costoVenta"},
      {label: "Vista al mar", key: "seaview"},
      {label: "Amueblado", key: "furnished"},
      {label: "Tipo de amueblado", key: "furnishType"},
      {label: "Ambientes", key: "environments"},
      {label: "Salida a la playa", key: "outing_to_the_beach"},
      {label: "Antigüedad de la casa", key: "house_antiquity"},
      {label: "Disponible", key: "available"},
      {label: "Estatus", key: "status"},
      {label: "Suspendido", key: "suspended"},
      {label: "Fecha de creación", key: "createdAt"},
      {label: "Asociados", key: "partners"},
      {label: "URL Maps Embed", key: "mapsUrl"},
      {label: "URL Maps Compartir", key: "mapsShareUrl"},
   ]

   useEffect(() => {
      const getData = async () => {
         setLoading(true)
         const inms = await getAllInmuebles()
         setInms(inms.data)
         const condos = await getAllConds()
         // transform to a map, where id of each condo is the key
         // and the name is the value
         const condosMap = condos?.data?.data?.reduce((acc: any, curr: any) => {
            acc[curr.id] = curr.name
            return acc
         }, {})
         setCondos(condosMap)
         const asociates = await getAssociates()
         // transform associates to map, where id is the key
         // and the name is the value
         const asociatesMap = asociates.reduce((acc: any, curr: any) => {
            acc[curr.id] = curr.name
            return acc
         }, {})
         setAsociates(asociatesMap)
         setLoading(false)
      }
      getData()
   }, [])

   function parseDataForCSV(data: any[]) {
      return data.map((inm) => ({
         ...inm,
         condo: condos[inm.condo],
         type: inm.type,
         levels: inm.levels && `${inm.levels} ${inm.levels === 1 ? "nivel" : "niveles"}`,
         rooms: `${inm.rooms} ${inm.rooms === 1 ? "habitación" : "habitaciones"}`,
         bathrooms: `${inm.bathrooms} ${inm.bathrooms === 1 ? "baño" : "baños"}`,
         parkingLots: `${inm.parkingLots} ${inm.parkingLots === 1 ? "cajón" : "cajones"}`,
         floor: inm.floor && `Piso ${inm.floor}`,
         costos: {
            mensual_anual: inm.costos?.mensual_anual?.toLocaleString("en-US", {
               style: "currency",
               currency: "MXN",
            }),
            mensual_regular: inm.costos?.mensual_regular?.toLocaleString("en-US", {
               style: "currency",
               currency: "MXN",
            }),
            noche_alta: inm.costos?.noche_alta?.toLocaleString("en-US", {
               style: "currency",
               currency: "MXN",
            }),
            noche_regular: inm.costos?.noche_regular?.toLocaleString("en-US", {
               style: "currency",
               currency: "MXN",
            }),
            noche_media: inm.costos?.noche_media?.toLocaleString("en-US", {
               style: "currency",
               currency: "MXN",
            }),
         },
         pricePerMeter: inm.pricePerMeter?.toLocaleString("en-US", {
            style: "currency",
            currency: "MXN",
         }),
         maintenance: inm.maintenance?.toLocaleString("en-US", {
            style: "currency",
            currency: "MXN",
         }),
         costoVenta: inm.costoVenta?.toLocaleString("en-US", {
            style: "currency",
            currency: "MXN",
         }),
         environments: inm.environments?.join(", "),
         partners: inm.partners?.map((partner: string) => asociates[partner]).join(", "),
         createdAt: inm.createdAt && dayjs.unix(inm.createdAt._seconds).format("DD-MM-YYYY"),
         verInmueble: `${PROD_URL}/inmuebles/${inm.id}`,
      }))
   }

   if (loading) {
      return (
         <Page>
            <div className="font-bold text-xl w-full h-full text-center mt-10">Loading...</div>
         </Page>
      )
   }

   return (
      <Page>
         <ArrowBackIcon
            className="font-bold mb-5 cursor-pointer mt-5"
            onClick={() => navigate("/inmuebles")}
         />
         <CSVLink
            headers={headers}
            data={parseDataForCSV(inms)}
            className="flex text-green-600 font-bold underline"
            filename="propiedades-cad.csv"
         >
            Descargar en formato CSV
         </CSVLink>
         <TableContainer component={Paper} sx={{width: "95%", height: "80%"}}>
            <Table stickyHeader aria-label="sticky table">
               <TableHead>
                  <TableRow>
                     {headers.map((header) => (
                        <TableCell
                           key={header.key}
                           align="center"
                           className="min-w-[150px]"
                           style={{fontSize: "10px"}}
                        >
                           {header.label}
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {inms.map((inm) => (
                     <TableRow key={inm.id} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           <VisibilityIcon
                              onClick={() => navigate(`/inmuebles/${inm.id}`)}
                              className="cursor-pointer hover:text-blue-600"
                           />
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>{inm.name}</TableCell>
                        <TableCell className="overflow-x-scroll" style={{fontSize: "10px"}}>
                           {inm.address}
                        </TableCell>
                        <TableCell className="whitespace-pre" style={{fontSize: "10px"}}>
                           <div className="w-[200px] overflow-scroll h-[50px]">{inm.desc}</div>
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           <div className="h-[50px] overflow-scroll">{inm.desc2}</div>
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           <div className="h-[50px] overflow-scroll">{inm.notes}</div>
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           <div className="h-[50px] overflow-scroll">{inm.privateNotes}</div>
                        </TableCell>
                        <TableCell style={{fontSize: "10px"}}>
                           <div className="w-[120px] overflow-x-scroll p-3">{inm.urlCAD}</div>
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.condo && condos[inm.condo]}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.type}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.capacity && `${inm.capacity} personas`}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.mts && `${inm.mts} mts2`}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.mtsConstruction && `${inm.mtsConstruction} mts2`}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.floor && `Piso ${inm.floor}`}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.levels && `${inm.levels} ${inm.levels === 1 ? "nivel" : "niveles"}`}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.rooms &&
                              `${inm.rooms} ${inm.rooms === 1 ? "habitación" : "habitaciones"}`}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.bathrooms &&
                              `${inm.bathrooms} ${inm.bathrooms === 1 ? "baño" : "baños"}`}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.parkingLots &&
                              `${inm.parkingLots} ${inm.parkingLots === 1 ? "cajón" : "cajones"}`}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.costos?.mensual_anual?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "MXN",
                           })}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.costos?.mensual_regular?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "MXN",
                           })}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.costos?.noche_alta?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "MXN",
                           })}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.costos?.noche_regular?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "MXN",
                           })}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.costos?.noche_media?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "MXN",
                           })}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.pricePerMeter?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "MXN",
                           })}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.maintenance?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "MXN",
                           })}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.costoVenta?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "MXN",
                           })}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.seaview !== undefined &&
                              (inm.seaview ? "Con vista al mar" : "Sin vista al mar")}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.furnished !== undefined &&
                              (inm.furnished ? "Amueblado" : "Sin amueblar")}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.furnishType !== undefined && inm.furnishType}
                        </TableCell>
                        <TableCell>
                           <div className="h-[50px] overflow-scroll" style={{fontSize: "10px"}}>
                              {inm.environments?.map((env: string) => (
                                 <p key={env} className="mb-2">
                                    {env}
                                 </p>
                              ))}
                           </div>
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.outing_to_the_beach !== undefined &&
                              (inm.outing_to_the_beach
                                 ? "Con salida a la playa"
                                 : "Sin salida a la playa")}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.house_antiquity}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.available ? "Disponible" : "No Disponible"}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.status}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.suspended ? "Suspendido" : "Sin suspender"}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.createdAt &&
                              dayjs.unix(inm.createdAt._seconds).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           {inm.partners?.map((partner: string) => (
                              <p key={partner} className="mb-2">
                                 {asociates[partner]}
                              </p>
                           ))}
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           <p>{inm.mapsUrl}</p>
                        </TableCell>
                        <TableCell align="center" style={{fontSize: "10px"}}>
                           <p>{inm.mapsShareUrl}</p>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Page>
   )
}

export default ExcelViewInm
