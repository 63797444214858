export enum PropertyType {
   HOUSE = "Casa",
   APARTMENT = "Departamento",
   LAND = "Terreno",
   COMMERCIAL = "Local/Oficina",
}

export enum Status {
   VERIFIED = "Inmueble verificado",
   FRACTIONAL = "Copropiedad/Fractional",
   PRESALE = "Preventa",
   TO_RESTORE = "Para restaurar",
   NOT_UPDATED = "Sin actualizar (por Otis)",
}

export type Property = {
   available: boolean
   bathrooms: number
   capacity: number
   condo: string
   condoName: string
   costos: {
      noche_regular: number
      noche_alta: number
      noche_media: number
      mensual_anual: number
      mensual_regular: number
   }
   costoVenta: number
   createdAt: {
      _seconds: number
      _nanoseconds: number
   }
   desc: string
   desc2: string
   environments: string[]
   floor: number
   furnished: boolean
   id: string
   images: {
      [key: string]: string[]
   }
   keywords: string[]
   levels: number
   mainImage: string
   mainImageUrl: string
   maintenance: number
   mts: number
   mtsConstruction: number
   name: string
   notes: string
   orderedSections: string[]
   parkingLots: number
   partners: string[]
   pricePerMeter: number
   rooms: number
   seaview: boolean
   suspended: boolean
   urlCAD: string
   videos: string[]
   outing_to_the_beach: boolean
   house_environments: string[]
   partnerNames: string[]
   condoClass: string
   house_antiquity: string
   zone: string
   videoID: string
   address: string
   mapsShareUrl: string
   status: Status
   forSale: boolean
   forRent: boolean
   type: PropertyType
   accessToBeach: string
   slug: string
}
