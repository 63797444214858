/* eslint-disable @typescript-eslint/no-explicit-any */
// axios
import axios from "axios"
// API_URL
import {API_URL, Status} from "../constants"

type stringOrNull = string | null

// get inmueble by id
type pathName = string

export const getInmuebleById = async (pathName: pathName) => {
   const res = await axios.get(`${API_URL}/inmuebles/inmueble/${pathName}`)
   return res
}

// get inmuebles
export const getInmuebles = async (lastInmueble: stringOrNull, q: string, searchPage: number) => {
   // If there is no lastInmueble, fetch based on query
   if (!lastInmueble || q !== "") {
      const res = await axios.get(
         `${API_URL}/inmuebles?sort=avoid&sociosQ=${q}&sociosSearchPage=${searchPage}`,
      )
      return res
   }
   // if there is a lastInmuebles, fetch more based on it and exclude query because it is not needed
   const res = await axios.get(`${API_URL}/inmuebles?lastInmueble=${lastInmueble}&sort=avoid`)
   return res
}

// order images of the inmueble
export const orderImages = async (id: string, images: any, inmueble: any) => {
   await axios({
      method: "PUT",
      url: `${API_URL}/inmuebles/inmueble/orderImages`,
      data: {id: id, images: images, inmueble: inmueble},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// post inmuele
export const postInmueble = async (inmueble: any) => {
   const resPost = await axios({
      method: "post",
      url: `${API_URL}/inmuebles/inmueble`,
      data: inmueble,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return resPost.data.inmueble
}

// put inmueble images
export const putInmueble = async (formData: FormData, id: any) => {
   const resPut = await axios({
      method: "put",
      url: `${API_URL}/inmuebles/inmueble/images/?id=${id}`,
      data: formData,
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data; charset=utf-8",
      },
   })
   return resPut
}

// edit inmueble
export const editInmueble = async (inmuebleUpdated: any) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/inmueble/data/`,
      data: inmuebleUpdated,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// delete property endpoint
export const deleteProperty = async (id: string) => {
   const res = await axios({
      method: "delete",
      url: `${API_URL}/inmuebles/inmueble/${id}`,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return res
}

// order sections
export const orderSections = async (id: string, images: any, categoriesUpdated: any) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/updateSections`,
      data: {
         id,
         orderedSections: categoriesUpdated,
         images,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// main image changes
export const mainImageChanges = async (id: string, formData: FormData) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/updateMainImage/${id}`,
      data: formData,
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data; charset=utf-8",
      },
   })
}

// add images
export const addImages = async (id: string, formData: FormData) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/addImages/${id}`,
      data: formData,
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data; charset=utf-8",
      },
   })
}

// delete images
export const deleteImages = async (id: string, deletedImages: string[]) => {
   await axios({
      method: "delete",
      url: `${API_URL}/inmuebles/deleteImages/${id}`,
      data: {
         deletedImages,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// delete section images
export const deleteSectionImages = async (
   id: string,
   categories: any,
   categoriesUpdated: any,
   images: any,
) => {
   const res = await axios({
      method: "delete",
      url: `${API_URL}/inmuebles/categoryImages`,
      data: {
         id,
         categories,
         categoriesUpdated,
         images,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return res.data.images
}

// delete main image
export const deleteMainImage = async (id: string, mainImage: string) => {
   await axios({
      method: "delete",
      url: `${API_URL}/inmuebles/mainImageDelete/${id}`,
      data: {
         mainImage,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// delete date of the inmueble
export const deleteDate = async (id: string, date: string, calendar: any) => {
   await axios({
      method: "delete",
      url: `${API_URL}/inmuebles/calendarDate/${id}`,
      data: {
         date,
         calendar,
      },
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// put date of the inmueble
export const putDate = async (id: string, calendar: any, calendarPast: any) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/calendar/${id}`,
      data: {calendar, calendarPast},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// edit date
export const editDate = async (id: string, calendar: any, calendarPast: any, apiDate: any) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/calendarDate/${id}`,
      data: {calendar, calendarPast, date: apiDate},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// edit availability
export const editAvailability = async (id: string, available: any) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/available/${id}`,
      data: {available},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// PATCH status
export const editStatus = async (id: string, status: Status) => {
   await axios({
      method: "PATCH",
      url: `${API_URL}/inmuebles/status/${id}`,
      data: {status},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// edit suspended
export const editSuspended = async (id: string, suspended: any) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/suspended/${id}`,
      data: {suspended},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// upload new Images
export const uploadNewImages = async (id: string, images: any) => {
   const res = await axios({
      method: "put",
      url: `${API_URL}/inmuebles/uploadNewImages/${id}`,
      data: images,
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data; charset=utf-8",
      },
   })
   return res.data.filePaths
}

// update notes
export const updateNotes = async (id: string, notes: any) => {
   await axios({
      method: "put",
      url: `${API_URL}/inmuebles/notes/${id}`,
      data: {notes},
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
}

// search by associates
export const searchByAssociates = async (associates: string[]) => {
   const res = await axios({
      method: "post",
      url: `${API_URL}/inmuebles/searchByAssociates`,
      data: associates,
      withCredentials: true,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return res.data
}

// get all inmuebles endpoint
export const getAllInmuebles = async () => {
   const res = await axios({
      method: "get",
      url: `${API_URL}/inmuebles/allInmuebles`,
      headers: {
         "Content-Type": "application/json",
      },
   })
   return res.data
}
