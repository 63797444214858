import React, {useState, useEffect} from "react"
import InmuebleCard from "@/components/properties/PropertyCard"
import Page from "@/layouts/Page"
import {getInmuebles, searchByAssociates} from "@/api/inmueble"
import {getAssociates} from "@/api/asociados"
import {Box} from "@mui/material"
import ListAltIcon from "@mui/icons-material/ListAlt"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, {SelectChangeEvent} from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import OutlinedInput from "@mui/material/OutlinedInput"
import {AxiosResponse} from "axios"
import {CADBLUE} from "@/constants"
import AddIcon from "@mui/icons-material/Add"
import {useAuthContext} from "@/hooks/useAuthContext"
import {Link} from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"

interface Partners {
   value: string
   label: string
}

function Inmuebles() {
   const [inmuebles, setInmuebles] = useState<any[]>([])
   const [lastInm, setLastInm] = useState<null | string>(null)
   const [loading, setLoading] = useState(false)
   const [fetchingMore, setFetchingMore] = useState(false)
   const [noMoreInm, setNoMoreInm] = useState(false)
   const [partners, setPartners] = useState<string[]>([])
   const [partnersFetched, setPartnersFetched] = useState<Partners[]>([])
   const [searchString, setSearchString] = useState("")
   const [searchPage, setSearchPage] = useState(0)
   const [totalHits, setTotalHits] = useState(0)
   const {user} = useAuthContext()

   // Local storage logic to preserve some state
   const storedSearchQuery = localStorage.getItem("query") || ""
   const setStoredSearchQuery = (val: string) => localStorage.setItem("query", val)

   const storedPartners = JSON.parse(localStorage.getItem("partners") || "[]")
   const setStoredPartners = (val: string[]) =>
      localStorage.setItem("partners", JSON.stringify(val))

   const fetchedProperties = JSON.parse(localStorage.getItem("properties") || "[]")
   const setFetchedProperties = (val: any[]) =>
      localStorage.setItem("properties", JSON.stringify(val))

   const fetchedPartners = JSON.parse(localStorage.getItem("fetchedPartners") || "[]")
   const setFetchedPartners = (val: any[]) =>
      localStorage.setItem("fetchedPartners", JSON.stringify(val))

   // run this when the component mounts
   useEffect(() => {
      const fetchPartners = async () => {
         if (fetchedPartners?.length > 0) {
            setPartnersFetched(fetchedPartners)
            return
         }
         const res = await getAssociates()
         const associates: Partners[] = []
         res.forEach((ass: any) => {
            associates.push({value: ass.id, label: ass.name})
         })
         setPartnersFetched(associates)
         setFetchedPartners(associates)
      }
      const fetchInmbuebles = async () => {
         setLoading(true)
         // fetch first inmuebles
         if (storedSearchQuery) {
            setSearchString(storedSearchQuery)
         }
         if (storedPartners?.length > 0) {
            setPartners(storedPartners)
         }
         if (fetchedProperties?.length > 0) {
            setLoading(false)
            setInmuebles(fetchedProperties)
            return
         }
         const res = await getInmuebles(
            null,
            storedSearchQuery ? storedSearchQuery : searchString,
            searchPage,
         )
         await changeState(res)
      }
      fetchPartners().then(() => fetchInmbuebles())
   }, [])

   // helper functions to change state and inmuebles
   const changeState = async (res: AxiosResponse<any, any>) => {
      // if nothing is found, return without changing any state
      if (res.data.data.length === 0) {
         setLoading(false)
         setInmuebles([])
         setFetchedProperties([])
         setNoMoreInm(true)
         return
      }
      // set inmuebles to the data we get from node
      setInmuebles(res.data.data)
      setFetchedProperties(res.data.data)
      if (res.data.totalHits) {
         setTotalHits(res.data.totalHits)
      } else {
         setTotalHits(0)
      }
      // asign the last inmueble to the last element of our response
      setLastInm(res.data.data[res.data.data.length - 1].name)
      setLoading(false)
   }

   const changeInmuebles = async (res: AxiosResponse<any, any>) => {
      // when more data is fetched, we just concatenate the previous inmuebles
      // with the new ones
      const newData = inmuebles.concat(res.data.data)
      // Sort the inmuebles by name in this case
      if (searchString) {
         newData.sort((a: any, b: any) => a.name.localeCompare(b.name))
      }
      setInmuebles(newData)
      setFetchedProperties(newData)
      // asign the last inmueble to the last element of our response
      if (res.data.data.length === 0) {
         setNoMoreInm(true)
      }
      setLastInm(res.data.data[res.data.data.length - 1]?.name)
      setLoading(false)
   }

   const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value)
      setStoredSearchQuery(e.target.value)
      if (e.target.value === "") {
         setSearchPage(0)
         setNoMoreInm(false)
         setInmuebles([])
         setFetchedProperties([])
         setLoading(true)
         const fetchInmbuebles = async () => {
            const res = await getInmuebles(null, "", 0)
            await changeState(res)
         }
         fetchInmbuebles()
      }
   }

   const handleSearch = async (e: React.FormEvent) => {
      e.preventDefault()
      setPartners([])
      setStoredPartners([])
      setLoading(true)
      setNoMoreInm(false)
      setTotalHits(0)
      const res = await getInmuebles(null, searchString, searchPage)
      // Sort the inmuebles by name in this case
      res.data.data.sort((a: any, b: any) => a.name.localeCompare(b.name))
      await changeState(res)
   }

   const clearSearchString = () => {
      setSearchString("")
      setStoredSearchQuery("")
      setPartners([])
      setStoredPartners([])
      setSearchPage(0)
      setTotalHits(0)
      setNoMoreInm(false)
      setInmuebles([])
      setFetchedProperties([])
      setLoading(true)
      const fetchInmbuebles = async () => {
         const res = await getInmuebles(null, "", 0)
         await changeState(res)
      }
      fetchInmbuebles()
   }

   const handleSearchByAssociates = async (e?: React.FormEvent, passedPartners?: string[]) => {
      e && e.preventDefault()
      setLoading(true)
      setNoMoreInm(true)
      // this variables is the one used for fetching more data for a searched value
      if (partners.length === 0 && passedPartners?.length === 0) {
         setLoading(false)
         return
      } else {
         const res = await searchByAssociates(passedPartners || partners)
         setStoredPartners(partners)
         setInmuebles(res.data)
         setFetchedProperties(res.data)
         setLoading(false)
      }
   }
   // multiple select
   const handleChangePartners = (event: SelectChangeEvent<typeof partners>) => {
      const {
         target: {value},
      } = event
      setPartners(
         // On autofill we get a stringified value.
         typeof value === "string" ? value.split(",") : value,
      )
   }

   const fetchMore = async () => {
      setFetchingMore(true)
      const res = await getInmuebles(lastInm, searchString, searchPage + 1)
      if (searchString) {
         setSearchPage(searchPage + 1)
      }
      await changeInmuebles(res)
      setFetchingMore(false)
   }

   return (
      <Page className="flex flex-col items-center my-8 gap-4 p-4 justify-center">
         <h1 className="text-2xl md:text-3xl font-bold text-blue text-center">
            Buscador de Propiedades Socios
         </h1>
         <section className="flex flex-col md:flex-row gap-4 md:gap-8">
            {user.admin && (
               <>
                  <Link to="nuevo" className="flex py-2 px-4 gap-2 rounded-full bg-blue text-white">
                     <AddIcon />
                     Agregar Inmueble
                  </Link>
                  <Link
                     to="excelView"
                     className="flex py-2 px-4 gap-2 rounded-full bg-blue text-white"
                  >
                     <ListAltIcon />
                     Ver modo Excel
                  </Link>
               </>
            )}
         </section>
         {user.admin && (
            <FormControl className="w-[250px]" color="primary" size="small">
               <InputLabel>Asociados</InputLabel>
               <Select
                  multiple
                  value={partners}
                  onChange={handleChangePartners}
                  input={<OutlinedInput label="Asociados" />}
                  renderValue={(selected) => (
                     <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                        {selected?.map((value) => {
                           const partner: any = partnersFetched.find(
                              (partner) => partner.value === value,
                           )
                           return (
                              <Chip
                                 key={partner?.value}
                                 label={partner?.label}
                                 sx={{bgcolor: CADBLUE, color: "white"}}
                              />
                           )
                        })}
                     </Box>
                  )}
               >
                  {partnersFetched?.map((associate) => (
                     <MenuItem key={associate.value} value={associate.value}>
                        {associate.label}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         )}
         <form
            className="flex flex-col items-center content-center gap-4"
            action="submit"
            onSubmit={searchString ? handleSearch : handleSearchByAssociates}
         >
            <div className="flex border border-blue rounded-md w-full px-2 py-1 items-center">
               <input
                  type="text"
                  name="Search String"
                  id="search-string"
                  value={searchString}
                  onChange={handleSearchStringChange}
                  className="px-2 py-1 focus:outline-none"
                  placeholder="Buscar por nombre"
               />
               <button onClick={clearSearchString} type="button">
                  <CloseIcon />
               </button>
            </div>
            <button type="submit" className="border rounded-[10px] bg-blue text-white px-2 py-1">
               Buscar
            </button>
         </form>
         {loading ? (
            <h2 className="text-center text-xl font-bold mt-20 animate-pulse">Cargando...</h2>
         ) : (
            <div>
               {searchString && !loading && totalHits > 0 && (
                  <h4 className="text-center font-bold my-4">
                     {totalHits} resultado{totalHits > 1 && "s"} para la búsqueda.
                  </h4>
               )}
               <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 items-center justify-center gap-8 mx-auto">
                  {inmuebles.map((inmueble) => (
                     <InmuebleCard key={inmueble.id} inmueble={inmueble} />
                  ))}
               </section>
            </div>
         )}
         <section className="my-8 flex flex-col gap-4">
            {fetchingMore ? (
               <h2 className="text-center text-xl font-bold mt-20 animate-pulse">
                  Cargando más...
               </h2>
            ) : (
               !noMoreInm &&
               (inmuebles.length % 20 === 0 || (searchString && inmuebles.length % 40 === 0)) && (
                  <button
                     onClick={fetchMore}
                     className="border rounded-[10px] bg-green text-white px-2 py-1"
                  >
                     Cargar más
                  </button>
               )
            )}
            <button
               onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
               className="border rounded-[10px] bg-green text-white px-2 py-1 flex items-center gap-2"
            >
               Volver arriba
               <ArrowUpwardIcon />
            </button>
         </section>
      </Page>
   )
}

export default Inmuebles
